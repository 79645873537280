import * as React from "react";
import { API_URL } from "../constans/appConfig";

export default function RingItems({
  productId,
  productName,
  price,
  imageUrl,
  milyem,
}) {
  const handlePriceFormat = (price) => {
    const ceiledPrice = Math.ceil(price);
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    }).format(ceiledPrice);
  };

  return (
    <a 
      href={"/products/detail?productId=" + productId}
      className="block w-full h-full group"
    >
      <div className="relative h-full bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 group-hover:shadow-2xl group-hover:scale-[1.02]">
        <div className="aspect-[3/4] w-full">
          <img
            src={`${API_URL}/${imageUrl}`}
            alt={productName}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/80 to-transparent p-4">
          <div className="text-center">
            <h3 className="text-sm md:text-base font-bold text-white mb-1 line-clamp-2">
              {productName.toUpperCase()}
            </h3>
            {milyem && (
              <p className="text-xs md:text-sm text-gray-200 mb-1">
                {milyem}
              </p>
            )}
            <p className="text-sm md:text-base font-semibold text-white">
              {handlePriceFormat(price)}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
}
