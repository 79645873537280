import "./App.css";
import Navbar from "./pages/Navbar/Navbar";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./pages/Footer/Footer";
import { useState, lazy, Suspense } from "react";
import { Button } from "@material-tailwind/react";
import { FaWhatsapp } from "react-icons/fa";
import { Spinner } from "@material-tailwind/react";

// Lazy loading için componentler
const Products = lazy(() => import("./pages/Product/Products"));
const ProductDetail = lazy(() => import("./pages/Product/ProductDetail"));
const Contact = lazy(() => import("./pages/Aboutus"));
const Communication = lazy(() => import("./pages/Communication"));

function App() {
  const [hovered, setHovered] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(4);

  const handleMouseEnter = () => {
    setHovered(true);
    setButtonWidth(10);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setButtonWidth(4);
  };

  const handleWhatsappMessage = () => {
    window.open("https://wa.me/903323234243", "_blank");
  };

  return (
    <div className="App min-h-screen bg-[#FBF8F4] font-[Inter] flex flex-col">
      <BrowserRouter>
        <div className="fixed bottom-3 right-4 z-50">
          <Button
            variant="gradient"
            color="green"
            style={{
              width: `${buttonWidth}rem`,
              height: "4rem",
              transition: "width 0.3s ease",
            }}
            className={`rounded-full shadow-2xl shadow-blue-gray-900 flex items-center ${
              hovered ? "justify-end" : "justify-center"
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? (
              <button
                onClick={handleWhatsappMessage}
                className="flex items-center justify-center gap-2 w-full"
              >
                <FaWhatsapp className="h-6 w-6 text-white" />
                <span className="text-white text-sm">Whatsapp</span>
              </button>
            ) : (
              <FaWhatsapp className="h-6 w-6 text-white" />
            )}
          </Button>
        </div>

        <Navbar />
        <main className="flex-grow">
          <Suspense 
            fallback={
              <div className="flex justify-center items-center h-screen">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/detail" element={<ProductDetail />} />
              <Route path="/aboutus" element={<Contact />} />
              <Route path="/contactus" element={<Communication />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
