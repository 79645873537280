import * as React from "react";
import { Link } from "react-router-dom";

export default function RingCard({ cardImage, cardTitle, categoryId }) {
  return (
    <Link
      to={`/products?&&subCategoryId=null&&categoryId=${categoryId}`}
      className="block w-full h-full group"
    >
      <div className="relative h-full bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 group-hover:shadow-2xl group-hover:scale-[1.02]">
        <div className="aspect-[3/4] w-full">
          <img
            src={cardImage}
            alt={cardTitle}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/80 to-transparent p-6">
          <div className="text-center">
            <h2 className="text-lg md:text-xl font-bold text-white mb-2">
              {cardTitle}
            </h2>
            <p className="text-sm md:text-base text-gray-200">
              TÜM MODELLERİ İNCELE
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
