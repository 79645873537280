import React from "react";
import { FaWhatsapp, FaInstagram, FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import dlkSoftLogo from "../../images/dileksoft.svg";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  const contactInfo = [
    {
      icon: <FaMapMarkerAlt className="text-xl" />,
      text: "Alavardı Mah. Yaka Cad. No: 10/A Meram / KONYA",
      link: "https://maps.app.goo.gl/D6qVNV9gYK5E2u3m7"
    },
    {
      icon: <FaPhone className="text-xl" />,
      text: "(0332) 323 42 43",
      link: "tel:03323234243"
    },
    {
      icon: <FaEnvelope className="text-xl" />,
      text: "bilgi@sarrafciraylar.com",
      link: "mailto:bilgi@sarrafciraylar.com"
    }
  ];

  const socialLinks = [
    {
      icon: <FaInstagram className="text-2xl" />,
      link: "https://www.instagram.com/sarrafciraylar/",
      label: "Instagram"
    },
    {
      icon: <FaWhatsapp className="text-2xl" />,
      link: "https://api.whatsapp.com/send?phone=903323234243",
      label: "WhatsApp"
    }
  ];

  return (
    <footer className="relative bg-gradient-to-br from-[#E6C0B4] to-[#d4a898] shadow-inner">
      {/* Üst Dalga Efekti */}
      <div className="absolute top-0 left-0 w-full overflow-hidden h-12">
        <svg
          className="absolute bottom-0 w-full h-12 text-white"
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="fill-current"
          />
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="fill-current"
          />
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="fill-current"
          />
        </svg>
      </div>

      <div className="container mx-auto px-4 pt-16 pb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Logo ve Hakkında */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">Sarraf Çıraylar</h3>
            <p className="text-white/90 text-sm">
              © {year} SARRAF ÇIRAYLAR ALTIN
              <br />
              Tüm Hakları Saklıdır.
            </p>
          </div>

          {/* İletişim Bilgileri */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">İletişim</h3>
            <div className="space-y-3">
              {contactInfo.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-3 text-white/90 hover:text-white transition-colors duration-300"
                >
                  {item.icon}
                  <span className="text-sm">{item.text}</span>
                </a>
              ))}
            </div>
          </div>

          {/* Hızlı Linkler */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">Hızlı Linkler</h3>
            <div className="space-y-2">
              <a href="/aboutus" className="block text-white/90 hover:text-white transition-colors duration-300">
                Hakkımızda
              </a>
              <a href="/contactus" className="block text-white/90 hover:text-white transition-colors duration-300">
                İletişim
              </a>
            </div>
          </div>

          {/* Sosyal Medya */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">Sosyal Medya</h3>
            <div className="flex gap-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center hover:bg-white/20 transform hover:scale-110 transition-all duration-300 group"
                  aria-label={social.label}
                >
                  <div className="text-white group-hover:text-white/90">
                    {social.icon}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Alt Kısım - Geliştirici Bilgisi */}
        <div className="pt-8 mt-8 border-t border-white/20">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 text-white" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"/>
                </svg>
              </div>
              <span className="text-white/80 text-sm font-medium">Geliştirici</span>
            </div>
            <a
              href="https://dileksoft.com"
              target="_blank"
              rel="noopener noreferrer"
              className="group bg-white/10 backdrop-blur-sm px-6 py-2 rounded-full hover:bg-white/20 transition-all duration-300"
            >
              <img
                src={dlkSoftLogo}
                alt="Dileksoft"
                className="h-8 md:h-10 transition-transform duration-300 group-hover:scale-105"
              />
            </a>
          </div>
        </div>
      </div>

      {/* Alt Dalga Efekti */}
      <div className="absolute bottom-0 left-0 w-full overflow-hidden h-12 rotate-180">
        <svg
          className="absolute bottom-0 w-full h-12 text-white opacity-10"
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            className="fill-current"
          />
        </svg>
      </div>
    </footer>
  );
};

export default Footer;
