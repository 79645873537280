import React, { useEffect, useState, memo } from "react";
import HeadersCarousel from "./components/HeadersCarousel";
import { API_URL } from "./constans/appConfig";
import { Spinner } from "@material-tailwind/react";
import RandomCarousel from "./components/randomCarousel";
import logo from "../images/logo.svg";

// RandomCarousel'i memoize ediyoruz
const MemoizedRandomCarousel = memo(RandomCarousel);

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <Spinner color="pink" className="h-32 w-32" />
    <img src={logo} alt="logo" className="h-40 w-40 mt-6" />
  </div>
);

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [carouselLoading, setCarouselLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getHomePageRandomCategory = async () => {
      try {
        const response = await fetch(
          `${API_URL}/homePageCategory/getAllHomePageCategoryDto`
        );
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        setFetchedData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Veri yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    getHomePageRandomCategory();
  }, []);

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  if (loading && carouselLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col font-[Inter] items-center gap-8 py-4 px-2 md:px-4 lg:px-6">
      <HeadersCarousel setCarouselLoading={setCarouselLoading} />
      {fetchedData.map((category, index) => (
        <MemoizedRandomCarousel key={category.id || index} category={category} />
      ))}
    </div>
  );
};

export default memo(Home);
