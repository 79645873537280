import RingItems from "../RingItems";
import RingCard from "../RingCard";
import { API_URL } from "../../constans/appConfig";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./style.css";

export default function RandomCarousel({ category }) {
  const categoryData = category;
  const categoryProducts = categoryData?.products;
  const categoryDescription = categoryData?.category;

  return (
    <section className="w-full py-8 md:py-12 ">
      <div className="container mx-auto px-4  md:px-6">
        {/* Kategori Başlığı */}
        {categoryData?.homePageCategoryName && (
          <div className="flex items-center justify-center mb-8 md:mb-12">
            <div className="h-[1px] flex-1 bg-gray-300/50"></div>
            <h2 className="px-4 text-xl md:text-2xl lg:text-3xl font-medium text-gray-800">
              {categoryData?.homePageCategoryName}
            </h2>
            <div className="h-[1px] flex-1 bg-gray-300/50"></div>
          </div>
        )}

        {/* Carousel Container */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-8">
          {/* Kategori Kartı */}
          <div className="md:col-span-3 lg:col-span-3 h-[400px]">
            <RingCard
              categoryId={categoryDescription?.categoryId}
              cardImage={`${API_URL}/${categoryData?.imagePath}`}
              cardTitle={categoryData?.homePageCategoryName}
            />
          </div>

          {/* Ürün Slider */}
          <div className="md:col-span-9 lg:col-span-9 h-[400px]">
            <Swiper
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="h-full product-carousel"
              breakpoints={{
                0: { slidesPerView: "auto", spaceBetween: 12 },
                640: { slidesPerView: "auto", spaceBetween: 16 },
                1024: { slidesPerView: "auto", spaceBetween: 20 },
              }}
            >
              {categoryProducts?.map(
                (product) =>
                  product.productVisible && (
                    <SwiperSlide
                      key={product.productId}
                      className="!w-auto h-full"
                    >
                      <RingItems
                        productId={product.productId}
                        productName={product.productName}
                        milyem={product.productTuningMilyem}
                        gram={product.productGram}
                        price={parseFloat(product.productPrice)}
                        imageUrl={product.productImage}
                      />
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
